<template>
  <div class="select-filter">
    <v-autocomplete
      v-model="selectOption"
      :items="allAgents"
      :label="placeholder"
      class="filter-select text-sm"
      clearable
      dense
      hide-details
      item-text="full_name"
      item-value="id"
      outlined
      single-line
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'AgentFilter',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Filtreaza...'
    }
  },
  data () {
    return {
      selectOption: ''
    }
  },
  computed: {
    agents () {
      return this.$store.getters['agents/data']
    },
    allAgents () {
      const agents = this.agents
      return [
        {
          full_name: 'Fara agent',
          id: 'null'
        },
        ...agents
      ]

    }
  },
  watch: {
    selectOption () {
      const value = this.selectOption || ''
      this.$emit('filter', value, this.filterName)
    }
  }
}
</script>


<style lang="scss">
.select-filter {

  .v-input__slot {
    min-height: 25px !important;
    padding: 0 10px !important;

    .v-text-field__slot {
      font-size: 10px !important;
    }

    .v-select__slot {
      font-weight: 400;

      .v-input__append-inner {
        margin-top: 4px !important;
      }

      label {
        font-size: 10px !important;
        top: 7px !important;
      }
    }

    .v-input__icon--clear {
      font-size: 16px !important;
    }
  }

  .v-input--is-label-active input {
    margin-top: 0;
  }
}
</style>
