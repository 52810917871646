<template>
  <v-autocomplete
    v-model="selectOption"
    :items="componentTypes"
    :label="label"
    :loading="loading"
    :search-input.sync="search"
    class="filter-select text-sm"
    hide-details
    outlined
    dense
    item-text="name"
    item-value="id"
    cache-items
    clearable
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'ProductSubcategorySelector',
  props: {
    value: {},
    label: {
      default: 'Categorie'
    },
    parentCategoryId:{
      required:true
    }
  },
  data () {
    return {
      paginatedData: [],
      loading: false,
      search: ''
    }
  },
  computed: {
    componentTypes () {
      return this.paginatedData
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/product-categories/${this.parentCategoryId}/children?filter[name]=${search}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
