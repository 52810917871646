<template>
  <v-card>
    <v-card-title v-if="!hideTitle">
      <v-row>
        <v-col class="flex flex-wrap flex-col md:flex-row justify-between">
          <slot v-bind="{ table, selectedItems }" name="actions"></slot>
          <v-btn class="ml-auto mr-1" @click="loadItems">
            <span class="mr-2">
              <v-icon size="small" small>
                fal fa-sync
              </v-icon>
            </span>
            Reimprospateaza
          </v-btn>
        </v-col>
        <v-col v-if="showSelect" cols="12">
          <div class="flex">
            <slot v-bind="{ table, selectedItems }" name="bulk_select_actions"></slot>
            <v-checkbox v-if="allSelected" v-model="bulkAll"
              :label="`Extinde selectia la toate ${paginatedData.meta.total} elementele`" class="select-all-checkbox"
              dense hide-details></v-checkbox>
          </div>
        </v-col>
        <v-col v-if="showBefore" cols="12">
          <slot v-bind="{ table }" name="before_table"></slot>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :id="uniqueId" :class="{ 'no-filters': hideFilters }" class="cit-data-table">
      <v-data-table v-model="selectedItems" :dense="dense" :disable-sort="disableSort" :expanded="expanded"
        :headers="allHeaders" :height="height" :items="paginatedData.data" :items-per-page="paginatedData.meta.per_page"
        :loading="loading" :multi-sort="false" :show-expand="showExpand" :show-select="showSelect"
        :single-expand="singleExpand" :sort-by.sync="sortFiled" :sort-desc.sync="sortIsDesc" fixed-header
        hide-default-footer loading-text="Incarcare..." mobile-breakpoint="300" @toggle-select-all="toggleSelectAll"
        @update:sort-by="updateSortField" @update:sort-desc="updateSortType">
        <template v-slot:progress>
          <loader :loading="true" colors="rgba(255, 255, 255, 0.90)" loader-color="#f0483d" size="fa-6x"></loader>
        </template>

        <template v-slot:header="{ props }">
          <thead v-if="!hideFilters" class="v-data-table-header extra-custom-header">
            <tr>
              <th v-for="(header, index) in props.headers" :key="index">
                <div>
                  <slot v-bind="{ header, table, updateFilter, loadItems, startLoading, stopLoading }"
                    :name="`filter.${header.value}`">

                    <template v-if="header.filterType === 'text'">
                      <text-filter :filter-name="header.filterName || header.value" @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'main-product-categories'">
                      <main-product-categories-filter :filter-name="header.filterName || header.value"
                        @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'component-type'">
                      <component-type-filter :filter-name="header.filterName || header.value" @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'brand'">
                      <brand-filter :filter-name="header.filterName || header.value" @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'component-group'">
                      <product-component-group-filter :filter-name="header.filterName || header.value"
                        @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'agent'">
                      <agent-filter :filter-name="header.filterName || header.value" @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'customer'">
                      <complex-customer-filter :filter-name="header.filterName || header.value" @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'customer-discount'">
                      <customer-discount-filter :filter-name="header.filterName || header.value" @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'date-range'">
                      <date-range-filter :filter-name="header.filterName || header.value" @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'boolean'">
                      <boolean-filter :filter-name="header.filterName || header.value" @filter="updateFilter" />
                    </template>

                    <template v-else-if="header.filterType === 'simple-select'">
                      <template v-if="header.filterOptions">
                        <simple-select-filter :filter-name="header.filterName || header.value"
                          :options="header.filterOptions" @filter="updateFilter" />
                      </template>
                      <template v-else>
                        <v-chip color="red" dark x-small>Nu exista optiuni</v-chip>
                      </template>
                    </template>

                  </slot>
                </div>
              </th>
            </tr>
          </thead>
        </template>

        <template v-for="field in headers" v-slot:[`item.${field.value}`]="{ item, value }">
          <slot v-bind="{ item, table, value, updateFilter, loadItems, startLoading, stopLoading }"
            :name="`item.${field.value}`">
            <template v-if="field.display === 'chip'">
              <v-chip :color="field.chipColor" :dark="field.chipDark">
                {{ value }}
              </v-chip>
            </template>
            <template v-else-if="field.display === 'image'">
              <template v-if="value">
                <template v-if="value.src">
                  <img :src="value.src" alt="" height="auto" width="50">
                </template>
                <template v-else>
                  <img :src="value" alt="" height="auto" width="50">
                </template>
              </template>
            </template>
            <template v-else-if="field.display === 'avatar'">
              <template v-if="value">
                <template>
                  <div class="m-4">
                  <v-avatar width="52" height="52" max-width="52">
                    <v-img :src="value" alt=""></v-img>
                  </v-avatar>
                </div>
                </template>
              </template>
            </template>
            <template v-else-if="field.display === 'date'">
              <template v-if="value">
                {{ value | moment('DD-MM-YYYY HH:mm:ss') }}
              </template>
            </template>
            <template v-else-if="field.display === 'product'">
              <template v-if="value.full_name && value.absolute_permalink">
                <a :href="value.absolute_permalink" target="_blank">
                  {{ value.full_name }}
                </a>
              </template>
            </template>
            <template v-else-if="field.display === 'phone'">
              <a :href="`tel:${value}`" class="text-dark underline">
                {{ value }}
              </a>
            </template>
            <template v-else-if="field.display === 'mail'">
              <a :href="`mailto:${value}`" class="text-dark underline">
                {{ value }}
              </a>
            </template>
            <template v-else-if="field.display === 'checkmarks' || field.display === 'boolean'">
              <div v-show="value">
                <v-icon color="primary" class="primary-color">far fa-check-square</v-icon>
              </div>
              <div v-show="!value">
                <v-icon color="red">far fa-window-close</v-icon>
              </div>
            </template>
            <template v-else-if="field.display === 'percent'">
              <template v-if="value >= 100">
                <span class="green--text">{{ value }} %</span>
              </template>
              <template v-else>
                {{ value }} %
              </template>
            </template>
            <template v-else-if="value || value === 0">
              {{ value }}
            </template>
            <template v-else>
              -
            </template>
          </slot>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="item-actions">
            <slot v-bind="{ item, table, updateFilter, loadItems, startLoading, stopLoading }" name="item.actions"></slot>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <slot v-bind="{ item, table, updateFilter, loadItems, startLoading, stopLoading }" name="expanded-item"></slot>
          </td>
        </template>

      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="showPagination">
      <v-pagination :length="paginatedData.meta.last_page" :total-visible="5" :value="page" color="#76bd43"
        @input="changePage"></v-pagination>
      <v-btn disabled>{{ paginatedData.meta.from }} - {{ paginatedData.meta.to }}</v-btn>
      <v-btn disabled>Total: {{ paginatedData.meta.total }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import Loader from '@/components/Loader'
import FilterInput from '@/components/general-form/FilterInput'
import TextFilter from '@/components/layout/CitDataTableFilters/TextFilter'
import AgentFilter from '@/components/layout/CitDataTableFilters/AgentFilter'
import CustomerDiscountFilter from '@/components/layout/CitDataTableFilters/CustomerDiscountFilter'
import DateRangeFilter from '@/components/layout/CitDataTableFilters/DateRangeFilter'
import SimpleSelectFilter from '@/components/layout/CitDataTableFilters/SimpleSelectFilter'
import ProductSubcategoryFilter from '@/components/layout/CitDataTableFilters/ProductSubcategoryFilter'
import ComplexCustomerFilter from '@/components/layout/CitDataTableFilters/ComplexCustomerFilter'
import MainProductCategoriesFilter from '@/components/layout/CitDataTableFilters/MainProductCategoriesFilter'
import ComponentTypeFilter from '@/components/layout/CitDataTableFilters/ComponentTypeFilter'
import ProductComponentGroupFilter from '@/components/layout/CitDataTableFilters/ProductComponentGroupFilter'
import BrandFilter from '@/components/layout/CitDataTableFilters/BrandFilter'
import BooleanFilter from '@/components/layout/CitDataTableFilters/BooleanFilter'

export default {
  components: {
    BooleanFilter,
    BrandFilter,
    ComplexCustomerFilter,
    ProductComponentGroupFilter,
    ComponentTypeFilter,
    MainProductCategoriesFilter,
    ProductSubcategoryFilter,
    SimpleSelectFilter,
    DateRangeFilter,
    CustomerDiscountFilter,
    AgentFilter,
    TextFilter,
    FilterInput,
    Loader
  },
  provide() {
    return {
      table: this
    }
  },
  props: {
    /**
     * Hide pagination if only one page
     */
    hidePagination: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    hideFilters: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    showBefore: {
      type: Boolean,
      default: false
    },
    fixedFilters: {
      type: Object,
      default: () => {
      }
    },
    headers: {
      type: Array,
      required: true,
      default: () => []
    },
    doubleScrollbar: {
      type: Boolean,
      default: true
    },
    height: {
      type: [String, Number],
      default: '65vh'
    },
    dense: Boolean,
    showExpand: Boolean,
    singleExpand: {
      type: Boolean,
      default: true
    },
    disableSort: Boolean,
    disableActions: Boolean,
    resourcePath: {
      type: String,
      required: true
    },
    appends: {
      type: Array,
      default: () => []
    },
    includes: {
      type: Array,
      default: () => []
    },
    perPage: {
      type: String,
      default: '10'
    }
  },
  data() {
    return {
      loading: false,
      paginatedData: {
        meta: {
          current_page: null,
          from: null,
          last_page: null,
          per_page: null,
          prev_page_url: null,
          to: null,
          total: null
        },
        data: []
      },
      sort: '',
      page: 1,
      filters: {},
      queryString: '',
      selectedItems: [],
      sortFiled: [],
      sortIsDesc: [],
      allSelected: false,
      bulkAll: false,
      expanded: []
    }
  },
  computed: {
    table() {
      return this
    },
    showPagination() {
      return !(this.hidePagination && this.paginatedData.meta.last_page === 1)
    },
    uniqueId() {
      return 'wrapper-' + this._uid
    },
    allHeaders() {
      let headers = this.headers
      if (!this.disableActions) {
        headers.push({
          text: '',
          value: 'actions',
          width: '150',
          sortable: false
        })
      }
      return headers
    },
    currentPage() {
      return this.page
    },
    sortParam() {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    toggleSelectAll(payload) {
      this.allSelected = payload.value
    },
    allFilters() {
      const fixedFilters = this.fixedFilters
      const filters = this.filters
      return {
        ...fixedFilters,
        ...filters
      }
    },
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    updateFilter(searchValue, filterName) {
      if (searchValue === '') {
        this.deleteFilter(filterName)
      } else {
        this.addFilter({
          filterName,
          searchValue
        })
      }
    },
    loadItems() {
      this.expanded = []
      this.startLoading()
      this.calculateQueryString()
      return new Promise((resolve, reject) => {
        this.$http.get(`/${this.resourcePath}?${this.queryString}`)
          .then(({ data }) => {
            this.paginatedData = data
            resolve(data)
          })
          .catch((e) => {
            this.$vs.notify({
              title: 'Eroare',
              text: e.response.data.message,
              color: 'danger'
            })
          })
          .finally(() => {
            this.stopLoading()
          })
      })
    },
    updateSortField(field) {
      this.sortFiled = field
    },
    updateSortType(isDesc) {
      this.sortIsDesc = isDesc
    },
    calculateQueryString() {
      let appends = ''

      if (this.appends.length > 0) {
        appends = '&append=' + this.appends.join(',')
      }

      let includes = ''
      if (this.includes.length > 0) {
        includes = '&include=' + this.includes.join(',')
      }
      const allFilters = this.allFilters()

      this.queryString = `page_size=${this.perPage}&page=${this.page}${appends}${includes}&sort=${this.sort}&${Object.keys(allFilters).map((key) => {
        return `filter[${key}]=${encodeURIComponent(allFilters[key])}`
      }).join('&')}`
    },

    changePage(page) {
      this.page = page
      this.loadItems()
    },
    deleteFilter(filterName) {
      this.page = 1
      delete this.filters[filterName]
      this.loadItems()
    },
    addFilter(filter) {
      this.page = 1
      this.filters[filter.filterName] = filter.searchValue
      this.loadItems()
    },
    changeSort(sort) {
      this.sort = sort
      this.loadItems()
    },

    makeDoubleScrollbar(element) {
      const scrollbar = document.createElement('div')
      scrollbar.appendChild(document.createElement('div'))
      scrollbar.style.overflow = 'auto'
      scrollbar.style.overflowY = 'hidden'
      scrollbar.firstChild.style.width = `${element.scrollWidth}px`
      scrollbar.firstChild.style.paddingTop = '1px'
      scrollbar.firstChild.appendChild(document.createTextNode('\xA0'))
      let running = false
      scrollbar.onscroll = function () {
        if (running) {
          running = false
          return
        }
        running = true
        element.scrollLeft = scrollbar.scrollLeft
      }
      element.onscroll = function () {
        if (running) {
          running = false
          return
        }
        running = true
        scrollbar.scrollLeft = element.scrollLeft
      }
      element.parentNode.insertBefore(scrollbar, element)
    }
  },
  watch: {
    sortParam(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.changeSort(newValue)
      }
    }
  },
  mounted() {
    if (this.resourcePath) {
      this.loadItems()
    }
    this.makeDoubleScrollbar(document.querySelector(`#${this.uniqueId} .v-data-table .v-data-table__wrapper`))
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}

.v-data-table tbody tr.v-data-table__expanded__content td {
  padding: 1.5rem;
}

.v-data-table.clickable-rows tr {
  cursor: pointer;
}

.v-data-table .item-actions {
  white-space: nowrap;
}

.v-data-table__progress .column {
  position: initial !important;

}

.cit-data-table-filters-row {
  height: 35px;
}

.cit-data-table-filters-row>th {
  background: #fff;
  border-bottom: 1px solid red;
}

.cit-data-table:not(.no-filters)>.v-data-table--fixed-header>.v-data-table__wrapper>table>thead:not(.extra-custom-header)>tr>th {
  position: sticky;
  top: 48px;
}

.select-all-checkbox {
  margin-top: 0;
  padding-top: 0;
}

.select-all-checkbox .v-label {
  font-size: 12px;
}
</style>

